<template>
  <div class="app-container" id="root">
    <button class="usa-skipnav" @click="goToMain" @keydown.enter="goToMain">
      Skip to main content
    </button>

    <!-- download warning -->
    <header class="app-container-warning beta-warning" v-if="showBetaWarning">
      <icon class="triangle" icon="info-circle"></icon>
      <span class="message"
        >STRAP is in beta, data is sensitive and confidential in nature. Please
        do not download or distribute. We welcome your feedback, please submit
        to ctrp_support@nih.gov.</span
      >
    </header>

    <!-- navbar -->
    <div class="app-nav-bar-container">
      <app-nav-bar v-if="showHeader"></app-nav-bar>
    </div>

    <!-- data warning -->
    <transition name="slide-fade-appear">
      <div
        class="app-container-warning"
        :key="$route.fullPath"
        v-if="showWarning"
      >
        <icon class="triangle" icon="info-circle"></icon>
        <span class="message">
          Showing data for the
          <b v-html="activeContainerName"></b>
        </span>
      </div>
    </transition>

    <!-- main -->
    <main id="main-content" tabindex="-1" class="app-content-container">
      <transition name="page" mode="out-in">
        <router-view :key="pageKey"></router-view>
      </transition>
    </main>

    <!-- footer -->
    <footer class="app-footer-container">
      <app-footer v-if="showFooter"></app-footer>
    </footer>

    <!-- portals -->
    <div class="app-portal">
      <portal-target name="top"></portal-target>
    </div>
    <div class="app-portal">
      <portal-target name="destination"></portal-target>
    </div>
    <div class="app-portal">
      <portal-target name="globalsearch" slim></portal-target>
    </div>

    <!-- modals -->
    <data-view-modal v-if="dataViewOpen"></data-view-modal>
    <data-settings-modal v-if="dataSettingsOpen"></data-settings-modal>
    <form-summary-modal v-if="formSummaryOpen"></form-summary-modal>
    <export-modal v-if="exportOpen"></export-modal>
    <feature-modal v-if="featureOpen" />

    <!-- other -->
    <toaster />
    <session-modal />
  </div>
</template>

<script>
import AppNavBar from "@/views/navbar/AppNavBar";
import AppFooter from "@/views/footer/AppFooter";
import Toaster from "@/components/utils/Toaster";
import SessionModal from "@/views/modals/SessionModal";

import debounce from "lodash/debounce";
import get from "lodash/get";
import { mapGetters, mapActions } from "vuex";

const hideHeader = [
  "/",
  "/unauthorized",
  "/not-found",
  "/login",
  "/implicit/callback",
  "/login-page",
];

const hideFooter = ["/", "/unauthorized", "/not-found", "/implicit/callback"];
const hideBetaWarning = [
  "/",
  "/unauthorized",
  "/reports",
  "/not-found",
  "/login",
  "/implicit/callback",
  "/login-page",
];
const showContainerWarnings = [
  "home",
  "studies",
  "organizations",
  "investigators",
  "participants",
  "site",
  "investigator",
];

export default {
  name: "app",
  components: {
    AppNavBar,
    AppFooter,
    Toaster,
    SessionModal,
    DataViewModal: () => import("@/views/modals/DataViewModal"),
    DataSettingsModal: () => import("@/views/modals/DataSettingsModal"),
    FormSummaryModal: () => import("@/views/modals/FormSummaryModal"),
    ExportModal: () => import("@/views/modals/ExportModal"),
    FeatureModal: () => import("@/views/modals/FeatureModal"),
  },
  computed: {
    ...mapGetters({
      dataViewOpen: "modal/dataViewOpen",
      dataSettingsOpen: "modal/dataSettingsOpen",
      formSummaryOpen: "modal/formSummaryOpen",
      exportOpen: "modal/exportOpen",
      featureOpen: "modal/featureOpen",
      containerEnabled: "settings/containerEnabled",
      useOriginalDiseases: "settings/useOriginalDiseases",
      showSummaryPatients: "settings/showSummaryPatients",
      memberInformation: "settings/memberInformation",
      activeContainer: "settings/activeContainer",
      skipCache: "settings/skipCache",
    }),
    showHeader() {
      return !hideHeader.includes(this.$route.path);
    },
    showFooter() {
      return !hideFooter.includes(this.$route.path);
    },
    showBetaWarning() {
      return !hideBetaWarning.includes(this.$route.path);
    },
    showWarning() {
      return (
        showContainerWarnings.includes(this.$route.name) &&
        this.containerEnabled
      );
    },
    activeContainerName() {
      return this.activeContainer ? this.activeContainer.name : "";
    },
    pageKey() {
      return (
        this.$route.fullPath +
        this.containerEnabled +
        this.useOriginalDiseases +
        this.skipCache +
        this.showSummaryPatients +
        this.activeContainerName +
        this.memberInformation
      );
    },
  },
  created() {
    this.dResize = debounce(this.resize, 400);
    this.addUser();

    // fail safe
    setTimeout(() => {
      this.$store.commit("toaster/setOpen", false);
    }, 5000);
    setTimeout(() => {
      this.$store.commit("toaster/setOpen", false);
    }, 15000);
  },
  mounted() {
    window.addEventListener("resize", this.dResize, {
      passive: true,
      capture: false,
    });
    this.$nextTick(this.resize);
    this.$store.dispatch("modal/closeModals");
  },
  methods: {
    ...mapActions(["resize"]),
    addUser() {
      const claims = getClaims();
      if (claims === undefined) {
        setTimeout(this.addUser, 100);
      } else {
        if (this.$store.getters["auth/email"] === "") {
          this.$store.dispatch("auth/setUser", {
            name: this.$h.get(claims, "name", claims.email),
            email: claims.email,
            roles: this.$h.get(claims, "roles", []),
            containerAffiliation: claims.container_affiliation,
          });
          this.$store.dispatch("toaster/start", {
            message: "Welcome to STRAP",
          });
        }
      }
    },
    goToMain() {
      var mainEle = document.getElementById("main-content");
      window.scroll({
        top: mainEle.offsetTop,
      });
      mainEle.focus();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.dResize, {
      passive: true,
      capture: false,
    });
  },
};

function getClaims() {
  const oktaStorage = JSON.parse(localStorage.getItem("okta-token-storage"));
  return get(oktaStorage, "idToken.claims");
}
</script>

<style lang="scss">
@import "styles/app";
.usa-skipnav {
  font-size: 1.06rem;
  line-height: 1.5;
  color: #005ea2;
  text-decoration: underline;
  background: 0 0;
  left: 0;
  padding: 0.5rem 1rem;
  position: absolute;
  top: -3.8rem;
  transition: 0.2s ease-in-out;
  z-index: 100;
  cursor: pointer;

  // Hide visually but keep accessible
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;

  &:focus {
    background: #fff;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
    transition: 0.2s ease-in-out;
  }
}

.app-container {
  width: 100%;
  height: auto;
  background-color: $light;
  background-color: white;
  position: relative;

  min-width: $min-width;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.app-nav-bar-container {
  flex: 0 0 auto;
}
.app-container-warning {
  flex: 0 0 auto;
  background-color: #fdf8ec;
  color: $warn-text-color;
  border-bottom: solid thin darken($warn-border-color, 10);
  text-align: center;
  padding: 0.5rem 0;

  .triangle {
    font-size: 1rem;
    margin-right: 0.75rem;
  }
  .message {
    font-weight: $regular;
    font-size: 0.9rem;
    span {
      font-weight: $thin;
    }
  }
  .manage {
    display: none;
    position: absolute;
    right: 2rem;
    margin-left: 1rem;
    padding: 0;
    background-color: transparent;
    border-color: transparent;

    color: $warn-text-color;
    font-weight: $regular;

    svg {
      margin-left: 0.35rem;
      font-size: 0.8rem;
    }
  }

  &.beta-warning {
    // padding: .6rem 0 .4rem;
    background-color: lighten($dark, 10);
    border-color: darken($dark, 30);
    color: white;
  }
}
.app-content-container {
  flex: 0 1 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
.app-footer-container {
  flex: 0 0 auto;
}
.app-top-portal {
  z-index: $z-index-portal;
}

.hide-overflow {
  overflow: hidden;
}

/* .chart-body {
  height: calc(100% - 100px);
}
.chart-query-summary {
  overflow: auto;
} */
</style>
